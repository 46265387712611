import { Save, RestorePage, FilterAlt, ChevronRightOutlined } from "@mui/icons-material";
import { Box, Chip, IconButton, Stack, Typography } from "@mui/material";
import { map, some } from "lodash";
import { useCallback } from "react";
import { lightTheme } from "../../assets/theme";
import { FilterContent } from "../../hooks/azureTableHooks";
import ActionResultBox, { ActionResultBoxProps } from "../DataTable/ActionBox";

interface FormToolbarProps {
  action?: ActionResultBoxProps;
  canSave: boolean;
  formName?: string;
  filters?: FilterContent[];

  onFilterOpen: () => void;
  onRestore: () => void;
  onSave: () => Promise<void>;
}

export const FormToolbar = (props: FormToolbarProps) => {

  const { action, canSave, formName, filters, onFilterOpen, onRestore, onSave } = props;

  const FilterBreadCrumbs = useCallback(() => {
    if (some(filters, f => !!f.selected)) {
      return <Stack display="flex" direction="row">
        {map(filters, (f, idx) => {
          const name = f.options.find(o => o.value.toString() === f.selected?.toString())?.name;
          if (!name) return null;
          return <Stack key={`crumb_${idx}`} display="flex" direction="row" alignItems="center">
            {idx !== 0 && <ChevronRightOutlined fontSize="small" color="disabled" />}
            <Chip label={name} size="small" />
          </Stack>
        })}
      </Stack>
    }
    return null;
  }, [filters]);


  return (
    <Box>
      <Box px={2} py={.5} borderBottom={1} borderColor={lightTheme.palette.grey[400]}
        display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography>{formName}</Typography>
        </Box>
        <Box>
          <ActionResultBox type={action?.type} message={action?.message} />
        </Box>
        <Box>
          <IconButton disabled={!canSave} onClick={onSave}>
            <Save />
          </IconButton>
          <IconButton disabled={!canSave} onClick={onRestore}>
            <RestorePage />
          </IconButton>
          <IconButton onClick={onFilterOpen}>
            <FilterAlt />
          </IconButton>
        </Box>
      </Box>
      <Box px={2} py={1}>
        <FilterBreadCrumbs />
      </Box>
    </Box>
  )
}