import fetchJson from "../fetchJson";
import { USER_SESSION_URL } from "../../services/serverUrl";
import { setCookies } from "cookies-next";
import { Session } from "../../hooks/userHooks";

export async function startSession(body: Session) {

  try {
    const res = await fetchJson(USER_SESSION_URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });
    return res;
  } catch (e: any) {
    throw new Error(e.data.message);
  }
}

export async function updateSession(body: Session) {
  try {
    const res = await fetchJson(USER_SESSION_URL, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });

    if(res?.token) {
      setCookies('adpt-auth-token', res.token)
    }

    return res;
  } catch (e: any) {
    return null;
  }
}