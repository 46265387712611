import { ThemeProvider } from '@emotion/react';
import DrawerContainer from '../DrawerContainer';
import { darkTheme } from '../../assets/theme';
import { useCallback } from 'react';
import LogoLink from '../LogoLink';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import TablesNavList from '../TablesNavList';
import { TableInfo } from '../../hooks/azureTableHooks';


interface TablesListDrawerProps {
  isOpen: boolean;
  tablesList: TableInfo[];
  onClose: () => void;
}

export const TablesListDrawer = (props: TablesListDrawerProps) => {

  const { isOpen, tablesList, onClose } = props;

  const MenuSection = useCallback(() => {
    return (
      <IconButton
        size="small"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mx: 1, padding: 0 }}
        onClick={onClose}
      >
        <MenuIcon fontSize="medium" />
      </IconButton>
    )
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <DrawerContainer
        open={isOpen}
        anchor="left"
        headerComponents={[
          <MenuSection key="header_drawer_menu_icon" />,
          <LogoLink
            key="header_drawer_logo_link"
            callback={onClose}
          />
        ]}
        onClose={onClose}
      >
        <TablesNavList tablesList={tablesList} />
      </DrawerContainer>
    </ThemeProvider>
  )
}