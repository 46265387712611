import { useMsal } from "@azure/msal-react";
import { PowerSettingsNew, Storage } from "@mui/icons-material";
import { Divider, Menu, MenuItem } from "@mui/material";
import { t } from "i18next";
import { isEmpty, map } from "lodash";
import { useCallback, useMemo } from "react";
import { InternalNav } from "../Util/InternalNav";
import { lightTheme } from '../../assets/theme';
import { Database, User } from "../../hooks/userHooks";


interface ProfileMenuProps {
  anchorEl: HTMLElement | null;
  databases: Database[];
  user?: User;
  isOpen: boolean;
  onSessionChange: (params: { database: string; serverId: string; view: string; username: string; }) => Promise<void>;
  onClose: () => void;
}

interface ItemProps {
  icon: JSX.Element;
  text: string;
  isSelected: boolean;
  navTo?: string;
  handleClick: () => void;
}

export const ProfileMenu = (props: ProfileMenuProps) => {

  const { databases, anchorEl, user, isOpen, onSessionChange, onClose } = props;
  const { instance } = useMsal();


  const handleLogout = useCallback(() => {
    instance.logoutRedirect().catch((e: any) => {
      console.error(e);
    });
    onClose();
  }, [instance]);


  const Item = useCallback((props: ItemProps) => {
    const { icon, text, isSelected, navTo, handleClick } = props;

    return (
      <MenuItem selected={isSelected} sx={{ padding: 0 }}>
        <InternalNav
          to={navTo ?? "/"}
          callback={handleClick}
          name={text}
          icon={icon}
          sx={{
            fontSize: 16,
            color: lightTheme.palette.common.black
          }}
        />
      </MenuItem>);
  }, []);

  const DatabaseSelection = useCallback(() => {
    if (isEmpty(databases)) return null;

    return (
      <>
        {map(databases, (db, index) => (
          <Item
            key={"database_item" + index}
            icon={<Storage />}
            text={`${db.displayName}: ${db.view}`}
            handleClick={async () => await onSessionChange({
              database: db.name,
              serverId: db.serverId,
              view: db.view,
              username: user!.username,
            })}
            isSelected={db.isActive} />
        ))}
        <Divider />
      </>);
  }, [databases, user]);


  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      keepMounted
      open={isOpen}
      onClose={onClose}
    >
      <DatabaseSelection />
      <Item
        icon={<PowerSettingsNew />}
        text={t("Logout")}
        isSelected={false}
        handleClick={handleLogout}
      />
    </Menu>
  )
}