export const API_BASE = "https://api.andmeladu.ee/api";
// export const API_BASE = "http://localhost:3001/api";

export const MESSAGES_URL = `${API_BASE}/messages`;
export const TABLES_LIST_URL = `${API_BASE}/tables`;
export const TABLE_CONTENT_URL = `${API_BASE}/tablecontent`;
export const TABLE_DATA_URL = `${API_BASE}/data`;
export const TABLE_COLUMN_OPTIONS_URL = `${API_BASE}/columnoptions`;
export const USER_URL = `${API_BASE}/users`;
export const USER_SESSION_URL = `${API_BASE}/session`;
export const CONTACTS_URL = `${API_BASE}/settings/contacts`;
export const HELP_URL = `${API_BASE}/help`;
export const FORMS_LIST_URL = `${API_BASE}/forms`;
export const FORM_CONTENT_URL = `${API_BASE}/formcontent`;


