import { Close } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DrawerContainer from "../DrawerContainer"

interface FilterDrawerProps {
  children: JSX.Element;
  open: boolean;
  closeDrawer: () => void;
}

export const FilterDrawer = (props: FilterDrawerProps) => {

  const { children, open, closeDrawer } = props;

  const { t } = useTranslation("translations");

  return (
    <DrawerContainer
      open={open}
      anchor={"right"}
      justifyHeaderContent="space-between"
      headerComponents={[
        <Typography key="right_drawer_title">{t("filter")}</Typography>,
        <Box key="right_drawer_buttons">
          <IconButton aria-label="close" onClick={closeDrawer}>
            <Close />
          </IconButton>
        </Box>
      ]}
      onClose={closeDrawer}
      width={{
        xs: "100%",
        sm: "55%",
        md: "40%",
        lg: "30%"
      }}
    >
      {children}
    </DrawerContainer>
  )
}