import { IconButton, IconButtonProps } from "@mui/material";

interface MenuIconButtonProps extends IconButtonProps {
  icon: JSX.Element;
}

export const MenuIconButton = (props: MenuIconButtonProps) => {

  const { icon, ...rest } = props

  return (<IconButton
    {...rest}
    size="small"
    aria-label="help links "
    aria-controls="help-appbar"
    aria-haspopup="true"
    color="inherit"
  >
    {icon}
  </IconButton>)

}