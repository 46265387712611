import { getCookie } from 'cookies-next';

interface FetcherError extends Error {
  data: any;
  response: Response;
}

export default async function fetcher(path: RequestInfo, options: any) {
  try {
    const token = getCookie('adpt-auth-token');

    const response = await fetch(path, {
      ...options,
      headers: {
        "Content-Type": "application/json",
        ...(options?.headers, {}),
        Authorization: token,
      }
    });


    // if the server replies, there's always some data in json
    // if there's a network error, it will throw at the previous line
    const data = await response.json();
    if (response.ok) {
      return data;
    }

    throw { ... new Error(response.statusText), ...{ response: response, data: data } };
  } catch (error) {
    const err = error as FetcherError;
    if (!err.data) {
      err.data = { message: err.message };
    }
    throw error;
  }
}
