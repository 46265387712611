import { ContentRequestAttributes as ContentRequestAttributes, DataRow } from "../../hooks/azureTableHooks";
import { FORM_CONTENT_URL, TABLE_DATA_URL } from "../../services/serverUrl";
import fetchJson from "../fetchJson";

export default async function fetchContent(path: RequestInfo, attr: ContentRequestAttributes) {
  return await post(path, attr);
}


export async function postContent(tableAccessor?: string, data?: { [key: string]: any }) {
  if (!data) return;
  return await post(`${TABLE_DATA_URL}/${tableAccessor}`, data);
}


export async function postFormContent(formAccessor?: string, data?: { [key: string]: any }[]) {
  if (!data) return;
  return await post(`${FORM_CONTENT_URL}/${formAccessor}`, data);
}


async function post(path: RequestInfo, body: DataRow) {
  try {
    const res = await fetchJson(path, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });
    return res;
  } catch (e: any) {
    throw new Error(e.data.message);
  }
}