export const msalConfig = {
  auth: {
    clientId: "229cfc85-32bf-41a6-bba6-fd6ba0d61cb5",
    authority: "https://login.microsoftonline.com/f01725fb-e05a-415f-9d95-39ad70ae07ae", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri:   process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://mdm.andmeladu.ee",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};