import { set } from "lodash";
import { useCallback } from "react";
import { useSession } from "./userHooks";

interface TabaleSettingsProps {
  tableAccessor?: string;
}

export interface TableSettings {
  tableAccessor: string;
  username: string;
  serverId?: string;
  database?: string;
  columns?: {
    [columnId: string]: {
      width?: number;
    }
  }
}

export const useTableSettings = (props: TabaleSettingsProps) => {

  const { tableAccessor } = props;

  const { session } = useSession(true);

  if (!session) { throw new Error("No session"); }

  const getTableSettings = useCallback(() => {

    if (!tableAccessor) { throw new Error("No table selected"); }

    const { username, database, serverId } = session;
    const stored = localStorage.getItem("settings");
    const settings: TableSettings[] = stored ? JSON.parse(stored) : [];

    let tableSettings = settings.find(s =>
      s.tableAccessor === tableAccessor &&
      s.username === username &&
      s.database === database &&
      s.serverId === serverId
    );

    if (!tableSettings) {
      const newSettingsSet = { tableAccessor, username, database, serverId, columns: {} };
      settings.push(newSettingsSet);
      localStorage.setItem("settings", JSON.stringify(settings));
      return newSettingsSet;
    }

    return tableSettings ?? [];
  }, [session, tableAccessor]);


  const setColumnWidth = useCallback((columnId: string, width: number) => {
    const storedSettings = getTableSettings();
    set(storedSettings, `columns.${columnId}.width`, width)

    const updatedSettings = ((JSON.parse(localStorage.getItem("settings") ?? "") ?? []) as TableSettings[])
      .map(s => {
        const { username, database, serverId, tableAccessor } = storedSettings;
        if (s.tableAccessor === tableAccessor && s.username === username && s.database === database && s.serverId === serverId) {
          return storedSettings;
        }
        return s
      })

    localStorage.setItem("settings", JSON.stringify(updatedSettings));
  }, [getTableSettings]);


  return {
    getTableSettings,
    setColumnWidth,
  }
}