import { ListItem } from "@mui/material";
import { InternalNav } from "../Util/InternalNav";

interface TableNavItemProps {
  nested?: boolean;
  routeTo: string;
  text: string;
}

export default function TableNavItem(props: TableNavItemProps) {
  return (
    <ListItem button sx={{padding: 0}}>
      <InternalNav 
        to={props.routeTo} 
        name={props.text}
        sx={{
          fontSize: 16,
          padding: `8px 16px 8px ${props.nested ? "40" : "24"}px`,
          textAlign: "left",
          display: "block"
        }} />
    </ListItem>)
}