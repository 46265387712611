import useSWR from "swr";
import fetcher from "../services/fetchJson";
import { CONTACTS_URL } from "../services/serverUrl";

interface Contacts {
  emails: string[];
  phones: string[];
}


export function useContacts() {
  const { data: contacts } =
    useSWR<Contacts>(CONTACTS_URL,
      fetcher,
      {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      });

  return { contacts }
}