import { Theme } from '@mui/material';

export const sxIconStyle = { marginTop: 1 };

export const getContainerStyle = (theme: Theme): object => {
  return (
    {
      display: 'flex',
      marginTop: '10vh',
      justifyContent: 'center',
      alignItems: 'center',
    }
  );
};

export const getBoxStyle = (theme: Theme): object => {
  return (
    {
      height: '300px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: '16px 24px',
    }
  );
};