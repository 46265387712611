import { IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';


interface MenuButtonProps {
  onClick: () => void;
}

export const MenuButton = (props: MenuButtonProps) => {

  const { onClick } = props;

  return (
    <IconButton
      size="small"
      edge="start"
      color="inherit"
      aria-label="menu"
      sx={{ mx: 1, padding: 0 }}
      onClick={onClick}
    >
      <MenuIcon fontSize="medium" />
    </IconButton>
  )
}