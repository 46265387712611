import { Email, Phone } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useContacts } from "../../hooks/settingsHooks";

interface ContactInfoProps {
  message?: string;
}

export default function ContactInfo(props: ContactInfoProps) {

  const { message } = props;
  const { contacts } = useContacts();

  if (isEmpty(contacts)) {
    return <></>
  }

  const Row = (props: { type: "email" | "phone" }) => {

    const { type } = props;
    const data = type === "email" ? contacts?.emails : contacts?.phones;

    if (isEmpty(data)) {
      return null;
    };

    return (
      <Stack direction="row" alignItems="center">
        {type === "email" ? <Email sx={{ mr: 1 }} /> : <Phone sx={{ mr: 1 }} />}
        <Typography variant="body2">{data!.join(", ")}</Typography>
      </Stack>
    )
  }

  return <>
    <Typography variant="body2">{message}</Typography>
    <Stack direction="column" p={1} gap={1}>
      <Row type="phone" />
      <Row type="email" />
    </Stack>
  </>
}