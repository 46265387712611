import useSWR from "swr";
import fetcher from "../services/fetchJson";
import { USER_SESSION_URL, USER_URL, MESSAGES_URL } from "../services/serverUrl";
import { groupBy } from "lodash";

export interface User {
  username: string;
  language: string;
  groups?: string[];
  isMdmAdmin: boolean;
}

export interface Database {
  name: string;
  displayName: string;
  serverId: string;
  view: string;
  isActive: boolean;
}

export interface Session {
  username: string;
  groups?: string[];
  serverId?: string;
  database?: string;
  view?: string;
  language?: string;
}

export interface Message {
  key: number;
  group: "VIEW" | "COMPANY";
  description: string;
  url: string;
  date: string;
}

export interface UserInfo {
  user: User;
  databases: Database[];
}

export function useUserInfoByUsername(username?: string) {
  const { data: userInfo, mutate: mutateUserInfo } =
    useSWR<UserInfo>(username ? `${USER_URL}?username=${username}` : null,
      fetcher,
      {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      });

  return { user: userInfo?.user, databases: userInfo?.databases, mutateUserInfo }
}

export function useSession(isAuthenticated: boolean) {
  const { data: session, mutate: mutateSession } = useSWR<Session>(
    isAuthenticated ? `${USER_SESSION_URL}` : null,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
    }
  );
  return { session, mutateSession };
}

export function useMessages() {
  const { data: messages, mutate: mutateMessages } = useSWR<Message[]>(MESSAGES_URL, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return { groupedMessages: groupBy(messages, (m) => m.group), mutateMessages };
}