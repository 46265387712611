import { GridEnrichedColDef, GridRenderCellParams, GridValueFormatterParams } from "@mui/x-data-grid";
import _ from "lodash";
import { ContentColumn } from "../../../hooks/azureTableHooks";

interface DataColumnContentProps {
  contentColumn: ContentColumn;
}

export default function DataColumnContent(props: DataColumnContentProps): GridEnrichedColDef {
  const c = props.contentColumn;
  return {
    field: c.accessor,
    headerName: c.name,
    type: c.dataType,
    editable: !c.readonly,
    flex: 1,
    valueOptions: c.valueOptions?.map(o => {
      return {
        value: o.value,
        label: o.label
      }
    }),
    valueFormatter: (params: GridValueFormatterParams) => {
      return c.valueOptions?.find(o => o.value === params.value)?.label;
    },
    renderCell: (params: GridRenderCellParams) => { 
      if(c.accessor === "Ky" && !_.isNumber(params.value)) return <span>#</span>
    }
  }
}