import { Divider, Toolbar } from "@mui/material";

export type JustifyContent = "flex-start" | "space-between";

interface DrawerHeaderProps {
  children: JSX.Element[];
  justifyContent?: JustifyContent;
}

export default function DrawerHeader(props: DrawerHeaderProps) {

  const { children, justifyContent } = props;

  return (
    <>
      <Toolbar sx={{ display: "flex", alignItems: "center", justifyContent: { justifyContent } }}>
        {children}
      </Toolbar>
      <Divider />
    </>)
}