import { Box } from "@mui/material";
import brandWhite from "../assets/adepta_logo.png";
import { useTranslation } from 'react-i18next';
import { InternalNav } from "./Util/InternalNav";

interface LogoLinkProps {
  height?: number;
  callback?: () => void;
}

export default function LogoLink(props: LogoLinkProps) {

  const { callback, height } = props;
  const { t } = useTranslation("translations");

  return (
    <Box
      display="flex"
      height={height ?? 24}
      sx={{ alignItems: "center" }}>
      <InternalNav
        to="/"
        name={t("brand")}
        sx={{
          fontWeight: "bold"
        }}
        icon={
          <Box key="logo_img" component="img" src={brandWhite} alt="Brand" maxHeight={24} height="inherit" />
        }
        callback={callback}
      />
    </Box>
  )
}