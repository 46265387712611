import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations_en from "./translations/en/translations.json";
import translations_et from "./translations/et/translations.json";

i18n
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === "development",
    resources: {
      en: {
        translations: translations_en,
      },
      et: {
        translations: translations_et,
      }
    },
    interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  }
  });


export default i18n;