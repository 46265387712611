import { ArrowRight } from "@mui/icons-material";
import { Box, Link as LinkBase, LinkProps as LinkPropsBase, Typography } from "@mui/material";
import { lightTheme } from "../../assets/theme";

interface LinkProps extends LinkPropsBase {
  title: string;
  url: string;
  height?: number;
}

export const Link = (props: LinkProps) => {

  const { title, url, height, ...rest } = props;

  return (
    <LinkBase
      {...rest}
      variant="body2"
      href={url}
      target="_blank"
      underline="hover"
      color={lightTheme.palette.common.black}
      width="100%"
      px={1}
      py={0.75}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        height={height ?? 28}>
        <Typography>
          {title}
        </Typography>
        <ArrowRight />
      </Box>
    </LinkBase>
  )
}