import { ContentCopy, Delete } from "@mui/icons-material";
import { GridActionsCellItem, GridEnrichedColDef, GridRowId, GridRowParams } from "@mui/x-data-grid";
import { DataRow } from "../../../hooks/azureTableHooks";

const iconStyle = {
  height: 15
}

interface ActionColumnContentProps {
  canDelete: boolean;
  deleteClick: (id: GridRowId) => void;
  duplicateClick: (id: GridRowId) => void;
}

export default function ActionColumnContent(props: ActionColumnContentProps): GridEnrichedColDef {
  const { canDelete, deleteClick, duplicateClick } = props;

  return {
    field: "actions",
    type: "actions",
    headerName: "",
    cellClassName: "actions",
    getActions: ({ id }: GridRowParams<DataRow>) => {
      const actions = [
        <GridActionsCellItem
          icon={<ContentCopy sx={iconStyle} />}
          label="Duplicate"
          color="inherit"
          onClick={() => duplicateClick(id)}
        />];

      if (canDelete)
        actions.push(
          <GridActionsCellItem
            icon={<Delete sx={iconStyle} />}
            label="Delete"
            color="inherit"
            onClick={() => deleteClick(id)}
          />)
      return actions;
    }
  }
}