import { useMemo } from 'react';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const useThemedStyles = (getStyleFunction: (theme: Theme) => object): object => {
  const theme = useTheme();
  return useMemo(
    () =>
      getStyleFunction(theme),
    [getStyleFunction, theme],
  );
};