import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";

const HEADER_HEIGHT = "28px !important";

const CompactDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-columnHeaders': {
    minHeight: HEADER_HEIGHT,
    maxHeight: HEADER_HEIGHT
  },
  '& .MuiDataGrid-footerContainer': {
    minHeight: HEADER_HEIGHT,
    maxHeight: HEADER_HEIGHT
  },
  '& .MuiDataGrid-toolbarContainer': {
    padding: 1,
    borderBottom: '1px solid ' + theme.palette.grey[300],
    justifyContent: 'space-between'
    
  },
  '& .MuiTablePagination-actions .MuiButtonBase-root': {
      padding: 0,
      margin: 10
    }
}))

export default CompactDataGrid;