import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { updateSession } from '../../services/requests/session';
import { useTranslation } from 'react-i18next';
import { Database, Session, User } from '../../hooks/userHooks';
import { InternalNav } from '../Util/InternalNav';
import { TablesListDrawer } from './tablesListDrawer';
import { MenuButton } from '../MenuButton';
import { SessionChangeAlert } from './sessionChangeAlert';
import { ProfileDropdown } from './profileSection';
import { ProfileMenu } from './profileMenu';
import { useTablesList } from '../../hooks/azureTableHooks';
import { HelpMenu } from './helpMenu';
import { HelpRounded } from '@mui/icons-material';
import { MenuIconButton } from '../IconButton';
import { useHelp } from '../../hooks/helpHooks';
import { isEmpty } from 'lodash';

interface MenuAppBarProps {
  isAuthenticated: boolean;
  user?: User;
  databases?: Database[];
  mutateSession: () => void;
}

export default function MenuAppBar(props: MenuAppBarProps) {

  const { isAuthenticated, mutateSession, user, databases } = props;
  const { tablesList, mutateTablesList } = useTablesList();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tablesDrawerOpen, setTablesDrawerOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [anchorHelpEl, setAnchorHelpEl] = useState<null | HTMLElement>(null);

  const profileOpen = Boolean(anchorEl);
  const helpOpen = Boolean(anchorHelpEl);
  const { helpItems } = useHelp();

  const navigate = useNavigate();
  const { t } = useTranslation("translations");

  const activeDatabase = useMemo(() => databases?.find(db => db.isActive), [databases]);

  const handleTablesDrawerOpen = useCallback(() => {
    setTablesDrawerOpen(prev => !prev);
  }, []);


  const handleMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);


  const handleProfileClose = useCallback(() => {
    setAnchorEl(null);
  }, []);


  const handleAlertOpen = useCallback(() => {
    setAlertOpen(prev => !prev);
  }, []);


  const handleHelp = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorHelpEl(event.currentTarget);
  }, []);


  const handleHelpClose = useCallback(() => {
    setAnchorHelpEl(null);
  }, []);


  const handleSessionChange = useCallback(
    async (session: Session) => {
      const updated = !!(await updateSession(session));
      if (updated) {
        mutateSession();
        handleProfileClose();
        mutateTablesList();
        navigate("/", { replace: true });
      } else {
        setAlertOpen(true);
      }
    },
    [mutateSession, navigate, handleProfileClose, mutateTablesList]
  );


  const HeaderSection = useCallback(() => {
    if (!isAuthenticated || !activeDatabase) return null;
    return <MenuButton onClick={handleTablesDrawerOpen} />;
  }, [isAuthenticated, activeDatabase, handleTablesDrawerOpen]);


  const ProfileSection = useCallback(() => {
    if (!user) return null;
    return <ProfileDropdown title={user.username} onClick={handleMenu} />
  }, [user, handleMenu]);


  const DatabaseHeader = useCallback(() => {
    if (!isAuthenticated) return null;
    return (
      <Typography fontWeight="600">
        {!!activeDatabase
          ? `${activeDatabase?.displayName ?? activeDatabase?.name}: ${activeDatabase.view}`
          : t("no_active_database")
        }
      </Typography>
    )
  }, [activeDatabase, isAuthenticated, t]);


  const HelpButton = useCallback(() => {
    if (!isAuthenticated || isEmpty(helpItems)) return null;
    return (
      <MenuIconButton 
        icon={<HelpRounded />}
        aria-label="help links"
        aria-controls="help-appbar"
        onClick={handleHelp}
      />
    );
  }, [isAuthenticated, handleHelp, helpItems]);

  return (
    <>
      <SessionChangeAlert isOpen={alertOpen} onClose={handleAlertOpen} />
      <AppBar position="static">
        <Toolbar
          sx={{
            height: "42px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <HeaderSection />
            <InternalNav
              name={t("header")}
              to="/"
              sx={{ fontSize: 16, padding: 0 }}
            />
          </Box>
          <DatabaseHeader />
          <Box display="flex" flexDirection="row">
            <ProfileSection />
            <ProfileMenu
              anchorEl={anchorEl}
              isOpen={profileOpen}
              databases={databases ?? []}
              user={user}
              onSessionChange={handleSessionChange}
              onClose={handleProfileClose}
            />
            <HelpButton />
            <HelpMenu anchorEl={anchorHelpEl} isOpen={helpOpen} onClose={handleHelpClose} />
          </Box>
        </Toolbar>
      </AppBar>
      <TablesListDrawer isOpen={tablesDrawerOpen} tablesList={tablesList ?? []} onClose={handleTablesDrawerOpen} />
    </>
  );
}
