import fetcher from '../services/fetchJson';
import useSWR from 'swr';
import { postContent } from '../services/requests/azureTable';
import { TABLES_LIST_URL, TABLE_CONTENT_URL, TABLE_DATA_URL } from '../services/serverUrl';
import { GridColumns, GridRowsProp, GridSortDirection } from '@mui/x-data-grid';

export enum YesOrNo {
  Yes = 'Y',
  No = 'N'
}

export interface DataRow {
  RwIsCrrnt?: YesOrNo;
  Ky?: string;
  [key: string]: any;
}

export type TTableType = "FORM" | "TABLE";

export type TDataType = "string" | "number" | "dateTime" | "singleSelect";

export interface TableInfo {
  id: string;
  accessor: string;
  name: string;
  folderName: string;
  type: TTableType;
}

export interface ContentColumn {
  accessor: string;
  dataType: TDataType;
  name: string;
  valueOptions: { value: number, label: string }[];
  readonly: boolean;
  description: string;
  width: number;
  format?: any;
}

export interface FilterContent {
  title: string;
  key: string;
  selected: string;
  options: {
    value: string;
    name: string;
  }[];
}

export interface ContentInfo {
  name: string;
  folderName?: string;
  deletableRows: boolean;
  columns: [ContentColumn];
  rowsCount: number;
  rows: DataRow[];
  success: boolean;
  userAccess: "EDIT" | "VIEW" | "COMPANY";
  rawQueryBaseScript: string;
  errorMsg: string;
  filterContent?: FilterContent[];
  canSubmitForm: boolean;
  queryCompleted: boolean;
}

export interface TableContent {
  name: string;
  folderName?: string;
  columns: GridColumns;
  rows: GridRowsProp;
}

export interface ContentRequestAttributes {
  rawQuery?: string;
  clauses?: FilterParams;
  pagination?: {
    offset: number;
    limit: number;
  };
  orderBy?: {
    field: string;
    sort: GridSortDirection;
  };
  filterFormQuery?: { [key: string]: string | number; };
}

export type FilterRange = [string, string];

export type FormFilterValue =  boolean | string | number | FilterRange | number[];

export interface FilterParams {
  [field: string]: FormFilterValue;
}

export interface SelectValue {
  value: number;
  label: string;
}

export function useTablesList() {
  const { data: tablesList, mutate: mutateTablesList } = useSWR<TableInfo[]>(`${TABLES_LIST_URL}`, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return { tablesList, mutateTablesList }
}


export const useTableContent = (tableAccessor?: string, body?: ContentRequestAttributes) => {

  const {
    data: tableContent,
    mutate: updateTableContent,
    error: tableContentError
  } = useSWR<ContentInfo>(
    tableAccessor ? [TABLE_CONTENT_URL + `/${tableAccessor}`, { method: 'POST', body: JSON.stringify(body) }] : null,
    fetcher,
  );

  return {
    tableContent,
    updateTableContent,
    tableContentError: tableContentError?.data.message
  }
}
