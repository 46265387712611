import { ContentColumn, FilterParams, FilterRange } from "../../hooks/azureTableHooks";
import { Box, Button, TextField, SelectChangeEvent, Divider } from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import _, { forIn, isArray, isEmpty } from "lodash"
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { HorizontalRule } from "@mui/icons-material";
import { MultiSelect } from "../Util/MultiSelect";

interface FilterFormProps {
  columns: ContentColumn[];
  filter: FilterParams;
  hideForm: boolean;
  onFormHide: (filter: FilterParams) => void;
  onSubmit: (filter: FilterParams) => void;
}

interface InputProps {
  content: ContentColumn;
  width?: number | string;
  rangeIndex?: 0 | 1;
}

export type Inputs = {
  [accessor: string]: string
};

export function FilterForm(props: FilterFormProps) {

  const { columns, onFormHide, onSubmit, filter, hideForm } = props;
  const { register, handleSubmit, getValues, reset } = useForm<Inputs>();
  const { t } = useTranslation("translations");

  // to manipulate multiselect values
  const [filterValues, setFilterValues] = useState(filter);

  const isSelect = (type: string) => type === "singleSelect";
  const isRange = (type: string) => !isSelect(type) && !["string", "boolean", "singleSelect"].includes(type);

  const cleanFilter = (values: { [x: string]: any }) => {
    let cleanedFilter = {}
    forIn(values, (value, key) => {
      const isValidFilterValue = isArray(value)
        ? !_(value).filter(a => a).isEmpty()
        : value;
      if (isValidFilterValue) {
        cleanedFilter = { ...cleanedFilter, ...{ [key]: value } }
      }
    })
    return { ...cleanedFilter, ...filterValues };
  }

  const submit: SubmitHandler<Inputs> = (values) => {
    onSubmit(cleanFilter(values));
  }

  useEffect(() => {
    if (hideForm) onFormHide(cleanFilter(getValues()));
  }, [hideForm])

  useEffect(() => {
    if (isEmpty(filter)) {
      reset();
      setFilterValues(filter);
    }
  }, [filter])

  const handleSelectChange = (event: SelectChangeEvent<number[]>) => {
    const { target: { value, name }, } = event;
    setFilterValues({ ...filterValues, ...{ [name]: value } });
  };

  const handleRemoveSelect = (field: string, value: number) => {
    setFilterValues({
      ...filterValues,
      ...{ [field]: (filterValues[field] as number[]).filter(v => v !== value) }
    });
  };

  const Input = ({ content, rangeIndex, width }: InputProps) => {
    const accessor = content.accessor;
    return (
      <TextField
        {...register(accessor + (rangeIndex !== undefined ? `.${rangeIndex}` : ""))}
        key={accessor + "_filterfield" + (rangeIndex)}
        type={content.dataType === "dateTime" ? "date" : content.dataType}
        label={content.name + (rangeIndex === 0 ? " from" : rangeIndex === 1 ? " to" : "")}
        defaultValue={rangeIndex === undefined
          ? filterValues[accessor]
          : filterValues[accessor] ? (filterValues[accessor] as FilterRange)[rangeIndex] : undefined}
        variant="standard"
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ width: width ?? "100%" }}
      />)
  }

  const Range = ({ content }: InputProps) => {
    return <>
      <Input content={content} rangeIndex={0} width="48%" />
      <HorizontalRule sx={{ width: "4%", marginTop: "17px", padding: "3px" }} />
      <Input content={content} rangeIndex={1} width="48%" />
    </>;
  }

  const Select = ({ content }: InputProps) => {
    const selectedValues = (filterValues[content.accessor] as number[] ?? []);
    return (
      <MultiSelect
        register={(f: string) => register(f)}
        fieldName={content.accessor}
        selectedValues={selectedValues}
        valueOptions={content.valueOptions}
        handleSelectChange={handleSelectChange}
        handleRemoveSelect={handleRemoveSelect}
      />
    )
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box height={'calc(100vh - 96px)'} overflow="scroll" p={1}>
        {columns.map(c => {
          return (
            <Box key={"formcontrol_" + c.accessor} mb={2}>
              {c.valueOptions
                ? <Select content={c} />
                : isRange(c.dataType) ? <Range content={c} /> : <Input content={c} />}
            </Box>)
        })}
      </Box>
      <Divider />
      <Box p={1}>
        <Button type="submit" variant="contained" color="info" fullWidth>
          {t("filter")}
        </Button>
      </Box>
    </form>
  )
}