import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { ExpandLess, Folder } from "@mui/icons-material";
import { Box, ListItemIcon } from "@mui/material";
import { collapseArrow, tableNavItem, collapseItemBox, collapseIconBox } from "./styles/tableNavCollapse";

interface TableNavCollapseProps {
  children: React.ReactNode;
  open: boolean;
  onClick: () => void;
  text: string;
}

export default function TableNavCollapse(props: TableNavCollapseProps) {
  return (
    <>
      <ListItem onClick={props.onClick} sx={() => tableNavItem()}>
        <Box sx={() =>
          collapseItemBox()
        }>
          <ListItemIcon sx={() => collapseIconBox()}>
            <Folder />
          </ListItemIcon>
          <ListItemText primary={props.text} />
          <ExpandLess sx={() => collapseArrow(props.open)} />
        </Box>
      </ListItem>
      {props.children && (
        <Collapse in={props.open} unmountOnExit>
          {props.children}
        </Collapse>
      )}
    </>
  );
}
