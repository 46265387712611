import useSWR from "swr";
import fetcher from "../services/fetchJson";
import { HELP_URL } from "../services/serverUrl";

interface HelpItem {
  title: string;
  url: string;
}

export function useHelp() {

  const { data: helpItems } =
    useSWR<HelpItem[]>(HELP_URL,
      fetcher,
      {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      });

  return { helpItems }
}