import ContactInfo from "../ContactInfo";
import Dialog from "../Dialog";

interface SessionChangeAlertProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SessionChangeAlert = (props: SessionChangeAlertProps) => {

  const { isOpen, onClose } = props;

  return (
    <Dialog type="alert"
      open={isOpen}
      handleClose={onClose}
      title={"Something went wrong"}
      dialogContentText={"Please contact us:"}
    >
      <ContactInfo />
    </Dialog>
  );
}