import { Box, Chip, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useMemo } from "react";
import { useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

interface MultiSelectProps {
  selectedValues: number[];
  fieldName: string;
  handleSelectChange: (event: SelectChangeEvent<number[]>) => void;
  handleRemoveSelect: (field: string, value: number) => void;
  valueOptions: { value: number, label: string }[];
  register: (fieldName: string) => UseFormRegisterReturn;
}

export function MultiSelect(props: MultiSelectProps) {

  const { fieldName, handleRemoveSelect, handleSelectChange, register, selectedValues, valueOptions } = props

  const [open, setOpen] = useState(false);

  const id = useMemo(() => `filter_select_${fieldName}`, [fieldName]);

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel shrink id={id + "_label"}>{fieldName}</InputLabel>
      <Select
        {...register(fieldName)}
        labelId={id + "_label"}
        id={id}
        defaultValue={[]}
        value={selectedValues}
        multiple
        onChange={handleSelectChange}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        renderValue={(selectedValues) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selectedValues.map((value) => (
              <Chip
                key={id + "_" + value + "_selected"}
                label={valueOptions.find(v => v.value === value)?.label}
                onDelete={() => handleRemoveSelect(fieldName, value)}
                onMouseDown={(event) => event.stopPropagation()}
              />
            ))}
          </Box>
        )}
      >
        {valueOptions
          .filter(o => !selectedValues.includes(o.value))
          .map(o => {
            return <MenuItem key={id + "_item" + o.value} value={o.value}>{o.label}</MenuItem>
          })}
      </Select>
    </FormControl>
  )
}