import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import List from "@mui/material/List";
import { TableInfo } from "../../hooks/azureTableHooks";
import { groupBy, orderBy } from "lodash";
import TableNavItem from "./TableNavItem";
import TableNavCollapse from "./TableNavCollapse";

interface TablesNavListProps {
  tablesList: TableInfo[];
}

export default function TablesNavList(props: TablesNavListProps) {
  const [openedFolder, setOpenedFolder] = useState("");

  const location = useLocation();
  const { pathname } = location;

  const { tablesList } = props;

  useEffect(() => {
    const selectedTableAccessor = pathname.split("/content/").slice(1)[0];
    setOpenedFolder((tablesList ?? []).find(t => t.accessor === selectedTableAccessor)?.folderName ?? "");
  }, [pathname, tablesList])

  const tableFolders = useMemo(() => {
    return groupBy(tablesList?.filter(t => t.folderName), t => t.folderName);
  }, [tablesList]);

  const getRoute = (ti: TableInfo) => `content${ti.type === "FORM" ? "_form" : ""}/${ti.accessor}`;

  const tablesWithFolder = useMemo(() => {
    return orderBy(Object.keys(tableFolders)
      .map((folderName, index) => {
        return {
          type: "collapse",
          name: folderName,
          key: "collapse" + index,
          collapse: tableFolders[folderName].map(t => {
            return {
              key: "nav_item_" + t.accessor,
              name: t.name,
              route: getRoute(t)
            }
          })
        }
      }), f => f.name)
  }, [tableFolders]);

  const tablesWithoutFolder = useMemo(() => {
    return orderBy(tablesList?.filter(t => !t.folderName).map(t => {
      return {
        type: "root",
        key: "nav_item_" + t.accessor,
        name: t.name,
        route: getRoute(t)
      }
    }), t => t.name)
  }, [tablesList]);

  return (
    <List>
      {tablesWithFolder?.map(c => {
        return <TableNavCollapse
          text={c.name}
          key={c.key}
          open={openedFolder === c.name}
          onClick={() => {
            setOpenedFolder(openedFolder === c.name ? "" : c.name);
          }}
        >
          <List>
            {c.collapse.map(ci => (
              <TableNavItem key={ci.key} routeTo={ci.route} text={ci.name} nested={true} />
            ))}
          </List>
        </TableNavCollapse>
      })}
      {tablesWithoutFolder?.map(c => (
        <TableNavItem key={c.key} routeTo={c.route} text={c.name} />
      ))}
    </List>
  )
}
