import { darkTheme } from "../../../assets/theme";

function tableNavItem(nested?: boolean) {
  const { palette } = darkTheme;

  return {
    paddingLeft: nested ? 5 : 3,
    color: palette.text.primary,
    "&:hover, &:focus": {
      backgroundColor: palette.action.hover,
    },
  };
}

function collapseItemBox() {
  return {
    display: "flex",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
    userSelect: "none",
    whiteSpace: "nowrap",
  };
}

function collapseArrow(open: boolean) {
  return {
    transform: open ? "rotate(0)" : "rotate(-180deg)",
  };
}

function collapseIconBox() {
  return {
    minWidth: 32,
    display: "grid",
    placeItems: "left",
  };
}

export { tableNavItem, collapseItemBox, collapseArrow, collapseIconBox };
