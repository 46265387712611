import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { useMessages, useSession } from "../../hooks/userHooks";
import { forEach } from "lodash"
import MessageCard from "../../components/MessageCard";
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from "react";
import i18n from "../../i18n";
import ContactInfo from "../../components/ContactInfo";

interface HomeProps {
  username: string;
  database?: string;
}

export default function Home(props: HomeProps) {

  const { database } = props;
  const { groupedMessages, mutateMessages } = useMessages();
  const { session } = useSession(true);

  useEffect(() => {
    mutateMessages();
  }, [database, mutateMessages]);

  const { t } = useTranslation("translations");
  useEffect(() => {
    i18n.changeLanguage(session?.language);
  }, [session])

  const messageGroupItems = useCallback(() => {
    const items: JSX.Element[] = [];

    let itemIndex = 0;
    forEach(groupedMessages, (messages, group) => {
      itemIndex++;
      items.push(
        <Grid item sm={4} key={"home_message_griditem" + itemIndex}>
          <MessageCard title={group} messages={messages} />
        </Grid>
      )
    })
    return items;
  }, [groupedMessages])

  return (
    <Container>
      <Grid container spacing={2} sx={{ marginTop: 0 }}>
        <Grid item xs={12}>
          <Paper sx={{ padding: 1.5 }}>
            <Typography variant="h6">{t("login.welcome")}</Typography>
          </Paper>
        </Grid>
        {!database &&
          <Grid item xs={12}>
            <Paper sx={{ padding: 1.5 }}>
              <Typography variant="subtitle1">{t("no_active_database")}</Typography>
              <Box py={1}>
                <ContactInfo message="If there are any issues, contact us:" />
              </Box>
            </Paper>
          </Grid>}
        <Grid item container spacing={1}>
          {messageGroupItems()}
        </Grid>
      </Grid>
    </Container>
  )
}
