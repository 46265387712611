const textAreaStyle = () => {
  return {
    style: {
      padding: 8,
      fontFamily: "monospace"
    }
  }
}

export default textAreaStyle;
