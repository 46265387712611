import { ArrowRight } from "@mui/icons-material"
import { Card, CardHeader, CardContent, Stack, Box, Typography, Link } from "@mui/material"
import { map } from "lodash"
import { Message } from "../hooks/userHooks"

interface MessageCardProps {
  title: string;
  messages: Message[];
}

export default function MessageCard(props: MessageCardProps) {

  const { title, messages } = props;

  return (
    <Card>
      <CardHeader
        title={title}
        titleTypographyProps={{
          fontWeight: "bold",
          variant: "subtitle2",
        }}
      />
      <CardContent>
        <Stack spacing={1}>
          {map(messages, m => {
            return (
              <Box
                key={"message" + m.key}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "baseline"
                }}>
                <Typography variant="body2">
                  {m.description}
                </Typography>
                <Link variant="body2" href={m.url} target="_blank" underline="hover" display="flex" alignItems="center">
                  LINK<ArrowRight fontSize="small" />
                </Link>
              </Box>
            )
          })}
        </Stack>
      </CardContent>
    </Card>
  )
}
