import { Box, Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import DrawerHeader, { JustifyContent } from "./DrawerHeader";

interface TablesDrawerProps {
  anchor: "left" | "top" | "bottom" | "right";
  children: React.ReactNode;
  headerComponents?: JSX.Element[];
  justifyHeaderContent?: JustifyContent;
  onClose: () => void;
  open: boolean;
  width?: {
    [field: string]: string 
  };
}

export default function DrawerContainer(props: TablesDrawerProps) {

  const { 
    anchor, 
    children, 
    headerComponents, 
    onClose, 
    justifyHeaderContent, 
    open: isOpen,
    width 
  } = props;

  const [open, setOpen] = useState(false);

  const toggleDrawer = (open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setOpen(open);
    };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen])

  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={() => {
        onClose();
        toggleDrawer(false);
      }}
      PaperProps={{
        sx: { width: {...width} },
      }}
    >
      <Box
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
      </Box>
      {!!headerComponents &&
        <DrawerHeader justifyContent={justifyHeaderContent}>
          {headerComponents}
        </DrawerHeader>}
      <Box>
        {children}
      </Box>
    </Drawer>)
}