import { Grid, IconButtonProps, Typography } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { ReactElement } from "react";
import ActionResultBox, { ActionResultBoxProps } from "./ActionBox";

interface CustomToolbarProps {
  actionResult?: ActionResultBoxProps;
  buttons: ReactElement<IconButtonProps>[];
  tableName: string | undefined;
};

export default function TableToolbar(props: CustomToolbarProps) {
  const { actionResult, buttons, tableName } = props;
  const { type: action, message: content } = actionResult ?? {};

  return <GridToolbarContainer>
    <Grid container justifyContent="flex-end">
      <Grid item xs={6} md={3} order={{ xs: 1, md: 1 }} p={1} flexWrap="nowrap">
        <Typography>{tableName}</Typography>
      </Grid>
      <Grid item xs={12} md={6} order={{ xs: 3, md: 2 }} p={0.5}>
        <ActionResultBox type={action} message={content} />
      </Grid>
      <Grid item xs={6} md={3} order={{ xs: 2, md: 3 }} textAlign={"right"}>
        {buttons}
      </Grid>
    </Grid>
  </GridToolbarContainer>
}
