import { ContentColumn, useTableContent, FilterParams, YesOrNo } from "../../hooks/azureTableHooks";
import { GridCellEditCommitParams, GridCellParams, GridRowId, GridSortModel, MuiEvent } from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { postContent } from "../../services/requests/azureTable";
import { find, get, isEmpty, isEqual, isNumber, map, reduce, reject, take, toString } from "lodash";
import { Badge, Box, Card, IconButton, Typography } from "@mui/material";
import { AddBox, BuildCircle, Close, FilterAlt, Refresh, RestorePage, Save } from "@mui/icons-material";
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { lightTheme } from "../../assets/theme";
import getTableStyle from "./Styles.ts";
import TableToolbar from "./TableToolbar";
import TableColumnContent from "./Columns/DataColumnContent";
import ActionColumnContent from "./Columns/ActionColumnContent";
import DrawerContainer from "../DrawerContainer";
import { FilterForm } from "./FilterForm";
import { RawFilterForm } from "./RawFilterForm";
import CompactDataGrid from "../CompactDataGrid";
import { ConfirmDialogProps } from "../Dialog";
import { ActionResultBoxProps } from "./ActionBox";
import { useLocation } from "react-router-dom";
import { CellEditForm } from "./CellEditForm";
import ContactInfo from "../ContactInfo";

interface ChangedRowUnit {
  rowId: GridRowId;
  originalRow?: { [key: string]: any; };
  currentRow?: { [key: string]: any; };
}

interface ValueUnit {
  [column: string]: any;
}

interface DataTableProps {
  tableAccessor?: string;
  setDialogData: (data?: ConfirmDialogProps) => void;
}

interface ContentParams {
  rawQuery: string | undefined;
  clauses: {};
  pagination: {
    offset: number;
    limit: number;
  }
  orderBy?: GridSortModel;
  filterContent?: {
    title: string;
    key: string;
    selected: string;
    options: {
      value: string;
      name: string;
    }[]
  }[];
}

const LIMIT = 100;
type Anchor = "bottom" | "right";
type Filter = { exactFilter: {}, rawFilter: string | undefined };

function DataTable(props: DataTableProps) {

  const { tableAccessor, setDialogData } = props;

  const { t } = useTranslation("translations");

  // Filter state 
  const [drawerOpen, setFilterFormOpen] = useState({ bottom: false, right: false });
  const [bottomDrawerContentType, setBottomDrawerContentType] = useState<"filter" | "editcell">();
  const [pendingFilter, setPendingFilter] = useState<Filter>({ exactFilter: {}, rawFilter: undefined });
  const [editableCell, setEditableCell] = useState<GridCellParams>();

  // Table functional state
  const [changedRows, setChangedRows] = useState<ChangedRowUnit[]>([]);
  const [newRows, setNewRows] = useState<ValueUnit[]>([]);
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [isActiveCell, setIsActiveCell] = useState(false);
  const [actionResult, setActionResult] = useState<ActionResultBoxProps | undefined>();
  const initialContentParams: ContentParams = useMemo<ContentParams>(() => {
    return {
      rawQuery: undefined,
      clauses: {},
      pagination: {
        offset: 0,
        limit: LIMIT
      },
      orderBy: [{
        field: "Ky",
        sort: "asc"
      }],
    }
  }, []);

  const [contentParams, setContentParams] = useState<ContentParams>(initialContentParams);
  const [page, setPage] = useState(initialContentParams.pagination.offset);


  // aggregated filter value
  const contentRequestAttributes = useMemo(() => {
    const paramsCopy = { ...contentParams };
    delete paramsCopy.orderBy;
    const contentOrderBy = contentParams?.orderBy;
    const attributes = {
      ...paramsCopy,
      ...{
        orderBy: {
          field: isEmpty(contentOrderBy) ? initialContentParams!.orderBy![0].field : contentOrderBy![0].field,
          sort: isEmpty(contentOrderBy) ? initialContentParams!.orderBy![0].sort : contentOrderBy![0].sort,
        }
      },
    }
    return attributes;
  }, [contentParams, initialContentParams])

  const { tableContent, updateTableContent, tableContentError } = useTableContent(tableAccessor, contentRequestAttributes);

  const canEdit = useMemo(() => tableContent?.userAccess !== "VIEW", [tableContent])

  // Data selectors
  const tableName = useMemo(() => {
    if (!!tableContent) {
      const { folderName, name } = tableContent || {};
      return `${folderName || ''}${folderName ? ' / ' : ''}${name}`;
    }
  }, [tableContent])

  /** Select table rows using remote data aggregated with local data. */
  const tableRows = useMemo(() => {
    if (!tableContent?.rows) return [] as ValueUnit[];
    const rows = reduce(tableContent.rows, (result, value) => {
      const changedValue = get(find(changedRows, { rowId: value.Ky }), 'currentRow', {});
      return [
        ...result,
        {
          ...value,
          ...changedValue,
        }
      ]
    }, [] as ValueUnit[])
    return [...newRows, ...rows] as ValueUnit[];
  }, [newRows, changedRows, tableContent?.rows])

  const toggleDrawer = useCallback((anchor: Anchor, open: boolean) => {
    setFilterFormOpen({ ...drawerOpen, [anchor]: open });
  }, [drawerOpen]);

  useEffect(() => {
    if (contentParams.rawQuery && tableContent?.errorMsg) {
      toggleDrawer("bottom", true);
    }
  }, [tableContent, contentParams.rawQuery, toggleDrawer])

  const location = useLocation();

  useEffect(() => {
    const rowsChanged = !isEmpty(changedRows) || !isEmpty(newRows);
    setCanSave(!isActiveCell && rowsChanged);
    window.history.pushState("", "", location.pathname + (rowsChanged ? "/#!" : ""));
  }, [isActiveCell, changedRows, newRows, location.pathname])

  // Table actions
  const clearTable = useCallback(() => {
    setChangedRows([]);
    setNewRows([]);
    setActionResult(undefined);
  }, [setChangedRows, setNewRows])

  const clearParams = useCallback(() => {
    setPendingFilter({ exactFilter: {}, rawFilter: undefined });
    setContentParams(initialContentParams);
  }, [initialContentParams])

  useEffect(() => {
    clearTable();
    clearParams();
  }, [tableAccessor, clearParams, clearTable])

  // Table row utils
  const isNewRow = (rowId: GridRowId) => !isNumber(rowId);

  const isDeletedRow = useCallback((rowId: GridRowId) => {
    const changedRow = find(changedRows, { rowId });
    if (!changedRow) return false;
    return !changedRow.currentRow;
  }, [changedRows])

  const isChangedValue = (rowId: GridRowId, field: string) => {
    const changedRow = find(changedRows, { rowId });
    if (!changedRow?.originalRow || !changedRow?.currentRow) return false;
    return changedRow.originalRow[field] !== changedRow.currentRow[field];
  }

  const getDefaultSelectValues = useCallback(() => {
    let defaultValues = {};
    tableContent?.columns.forEach(c => {
      if (c.dataType === "singleSelect") {
        const defaultValue = c.valueOptions.find(v => v.value === -1) ?? c.valueOptions[0];
        defaultValues = {
          ...defaultValues,
          ...{ [c.accessor]: defaultValue.value }
        }
      }
    });
    return defaultValues;
  }, [tableContent?.columns]);

  const scrollToTop = useCallback(() => {
    const selector = document?.querySelector('.MuiDataGrid-virtualScroller');
    if (selector) {
      selector.scrollTop = 0;
    }
  }, []);

  const setDataChangingAlert = useCallback(() => {
    setDialogData({
      title: t("datatable.alert_dialog.title"),
      type: "alert",
      dialogContentText: `${t("datatable.alert_dialog.content")}. ${t("contact_us")}`,
      children: <ContactInfo />
    });
  }, [setDialogData, t])

  const addNewRow = useCallback(
    (duplicateId?: GridRowId) => {
      if (!canEdit) {
        setDataChangingAlert()
        return;
      }
      const newRowId = uuid();
      const originRow = duplicateId ? find(tableRows, { Ky: duplicateId }) : {};

      let newRow = {
        ...originRow,
        Ky: newRowId,
      }

      if (!duplicateId) {
        newRow = {
          ...newRow,
          ...getDefaultSelectValues()
        }
        setActionResult({ type: "info", message: t("datatable.actions.new_row_added") });
      } else {
        setActionResult({ type: "info", message: t("datatable.actions.duplicated_row_created") });
      }

      setNewRows((previousRows) => {
        return [newRow, ...previousRows];
      });

      scrollToTop();
    },
    [canEdit, tableRows, scrollToTop, getDefaultSelectValues, setDataChangingAlert, t],
  );

  const removeDataRow = useCallback((rowId: GridRowId) => {
    if (isNewRow(rowId)) {
      // timeout need to set focus off the row before removing the row from table
      setTimeout(() => {
        setNewRows((previousRows) => reject(previousRows, { Ky: rowId }));
      })
    } else {
      setChangedRows((previousValues) => [...previousValues, { rowId }]);
    }
  }, [])


  const undoDeleteRemoteDataRow = useCallback((rowId: GridRowId) => {
    setChangedRows((previousValues) => reject(previousValues, { rowId }));
  }, [setChangedRows])

  const editCell = (params: GridCellEditCommitParams | GridCellParams) => {
    const rowId = params.id;
    const dataRow = find(tableRows, r => r.Ky === rowId)!;

    if (params.value === dataRow[params.field]) return;
    const dataRowOriginal = { ...dataRow };
    dataRow[params.field] = params.value;

    if (isNewRow(rowId)) {
      setNewRows(newRows);
    } else {
      let rows = [...changedRows]
      let changedRow = find(rows, { rowId });
      if (!changedRow) {
        rows = [...rows, {
          rowId,
          originalRow: dataRowOriginal,
          currentRow: dataRow
        }];
      } else if (isEqual(dataRow, changedRow.originalRow)) {
        rows = reject(rows, { rowId })
      } else {
        changedRow.currentRow = dataRow;
      }
      setChangedRows(rows)
    }
  }

  const onDeleteClick = useCallback((id: GridRowId) => {
    if (!canEdit) {
      setDataChangingAlert();
      return;
    } else if (isDeletedRow(id)) {
      undoDeleteRemoteDataRow(id);
    } else {
      removeDataRow(id);
    }
    setActionResult({
      type: "warning",
      message: t("datatable.actions.row_removed", { rowId: isNewRow(id) ? " " : ` ${id} ` })
    });
  }, [canEdit, isDeletedRow, removeDataRow, undoDeleteRemoteDataRow, setDataChangingAlert, t]);

  const columnsContent = useMemo(() => {
    if (tableContent) {
      const columnsContent = map(tableContent.columns, (contentColumn: ContentColumn) => TableColumnContent({ contentColumn }));

      return [...columnsContent, ActionColumnContent(
        {
          canDelete: tableContent.deletableRows ?? false,
          deleteClick: onDeleteClick,
          duplicateClick: addNewRow
        })]
    }
    else {
      return [];
    }
  }, [tableContent, addNewRow, onDeleteClick])

  const restoreTable = useCallback(() => {
    setDialogData({
      title: t("datatable.restore_dialog.title"),
      type: "confirm",
      dialogContentText: t("Changes in table {{tableName}} will be lost.", {
        tableName: tableContent?.name
      }),
      handleConfirm: () => {
        clearTable();
        setActionResult({ type: "success", message: t("datatable.actions.table_restored") });
      },
    });
  }, [setDialogData, tableContent?.name, setActionResult, clearTable, t])

  const saveTable = useCallback(async () => {
    const changedData = map(changedRows, (row) => {
      if (row.currentRow) return row.currentRow;
      const deletedRow = find(tableRows, { Ky: row.rowId })!;
      return { ...deletedRow, ...{ RwIsCrrnt: YesOrNo.No } }
    });

    const formattedNewRows = map(newRows, (row) => {
      const formattedRow = { ...row };
      delete formattedRow.Ky;
      return formattedRow;
    });

    const dataToSave = [...changedData, ...formattedNewRows];

    try {
      await postContent(tableAccessor || "", dataToSave);
      await updateTableContent();
      clearTable();
      setActionResult({ type: "success", message: t("datatable.actions.saved") });
    } catch (e: any) {
      setActionResult({ type: "error", message: toString(e) })
    }
  }, [changedRows, newRows, tableAccessor, clearTable, t, tableRows, updateTableContent])

  const handlePageChange = useCallback((pageNumber: number) => {
    setPage(pageNumber);
    setContentParams({
      ...contentParams,
      ...{
        pagination: {
          offset: Math.max(0, pageNumber * LIMIT - changedRows.length)!,
          limit: LIMIT
        }
      }
    })
  }, [changedRows.length, contentParams])

  const handleSortChange = useCallback((sortModel: GridSortModel) => {
    setContentParams({ ...contentParams, ...{ orderBy: sortModel } })
  }, [contentParams])

  const toolbarButtons = useMemo(() => {
    const commonButtons = [
      <IconButton
        key="toolbar_save_btn"
        disabled={!canSave}
        onClick={async () => {
          setSavingInProgress(true);
          await saveTable();
          setSavingInProgress(false);
        }
        }>
        <Save />
      </IconButton>,
      <IconButton
        key="toolbar_restore_btn"
        disabled={!canSave}
        onClick={restoreTable}>
        <RestorePage />
      </IconButton>,
      <IconButton
        key="toolbar_raw_filter_btn"
        onClick={() => {
          setBottomDrawerContentType("filter");
          toggleDrawer("bottom", true)
        }}
      >
        <Badge variant="dot" color="primary" invisible={isEmpty(contentParams.rawQuery)}>
          <BuildCircle />
        </Badge>
      </IconButton>,
      <IconButton
        key="toolbar_filter_btn"
        disabled={!!tableContentError}
        onClick={() => toggleDrawer("right", true)}
      >
        <Badge variant="dot" color="primary" invisible={isEmpty(contentParams.clauses)}>
          <FilterAlt />
        </Badge>
      </IconButton>
    ]

    return [
      <IconButton
        key="toolbar_add_btn"
        disabled={!!tableContentError}
        onClick={() => addNewRow()}
      >
        <AddBox />
      </IconButton>,
      ...commonButtons
    ]
  }, [canSave, contentParams, tableContentError, addNewRow, restoreTable, saveTable, toggleDrawer]);

  const newToolbar = useCallback(() => {
    return <TableToolbar
      actionResult={actionResult}
      tableName={tableName}
      buttons={toolbarButtons}
    />
  }, [toolbarButtons, actionResult, tableName])

  const bottomDrawerContent = useMemo(() => {
    if (tableContent && bottomDrawerContentType === "filter") {
      return {
        title: t("filter"),
        element: <RawFilterForm
          tableName={tableAccessor}
          filter={pendingFilter.rawFilter ?? ""}
          columns={tableContent.columns}
          hideForm={!drawerOpen["bottom"]}
          onFormHide={(filter: string) => setPendingFilter({ ...pendingFilter, ...{ rawFilter: filter } })}
          rawQueryBaseScript={tableContent.rawQueryBaseScript}
          error={tableContent.errorMsg}
          onSubmit={(filter: string) => {
            setPendingFilter({ ...pendingFilter, ...{ rawFilter: filter } });
            setContentParams({ ...contentParams, ...{ clauses: {}, rawQuery: filter } });
            toggleDrawer("bottom", false);
            setActionResult({ type: "info", message: t("datatable.actions.filtered") });
          }}
        />
      }
    } else if (editableCell && bottomDrawerContentType === "editcell") {
      return {
        title: t("datatable.editform", { row: editableCell.id, field: editableCell.field }),
        element: <CellEditForm
          cellParams={editableCell}
          onChange={(cellParams: GridCellParams) => {
            setEditableCell(cellParams);
          }} />
      }
    }
  }, [bottomDrawerContentType, editableCell, contentParams, drawerOpen, pendingFilter, t, tableAccessor, tableContent, toggleDrawer])

  const closeBottomDrawer = () => {
    if (editableCell && !isEmpty(editableCell)) {
      editCell(editableCell);
    }
    toggleDrawer("bottom", false);
  }

  return (
    <Card sx={{ height: 'calc(100vh - 42px)' }}>
      <CompactDataGrid
        sx={getTableStyle(lightTheme)}
        columns={columnsContent}
        components={{ Toolbar: newToolbar }}
        density="compact"
        disableColumnFilter
        getCellClassName={(params: GridCellParams) => {
          if (isNewRow(params.id)) return "new-cell"
          return isChangedValue(params.id, params.field) ? "changed-cell" : ""
        }}
        getRowId={r => r.Ky}
        getRowClassName={(params) => isDeletedRow(params.row.Ky) ? "deleted-row" : ""}
        hideFooterSelectedRowCount={true}
        loading={savingInProgress || (!tableContent && !tableContentError)}
        isCellEditable={(params: GridCellParams) => canEdit && !isDeletedRow(params.id)}
        onPageChange={handlePageChange}
        onSortModelChange={(sortModel: GridSortModel) => {
          if (isEmpty(sortModel)) sortModel = initialContentParams.orderBy!;
          const contentSortModel = contentParams?.orderBy ? contentParams?.orderBy[0] : undefined;
          if (sortModel[0].field !== contentSortModel?.field || sortModel[0].sort !== contentSortModel?.sort) {
            handleSortChange(sortModel);
          }
        }}
        page={page}
        pageSize={LIMIT}
        paginationMode="server"
        onCellEditCommit={(params: GridCellEditCommitParams) => editCell(params)}
        onCellEditStart={(params: GridCellParams) => setIsActiveCell(!params.colDef.valueOptions)}
        onCellEditStop={() => setIsActiveCell(false)}
        onCellClick={(params: GridCellParams) => {
          if (params.colDef.type === "actions") return;
          const originalRow = find(changedRows, r => r.rowId === params.id)?.originalRow;
          const originalValue = originalRow && originalRow[params.field] !== params.value
            ? ` (${originalRow[params.field]})`
            : "";
          setActionResult({
            type: "info",
            message: `${params.field}: ${params.formattedValue ?? params.value}${originalValue}`
          });
        }}
        onCellDoubleClick={(params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
          if (!canEdit && params.colDef.field !== "Ky" && params.colDef.type !== "actions") {
            setDataChangingAlert();
          } else if (params.colDef.editable && !params.colDef.valueOptions && event.altKey) {
            setBottomDrawerContentType("editcell");
            setEditableCell(params);
            toggleDrawer("bottom", true);
          } else if (!params.colDef.editable && params.colDef.type !== "actions") {
            setDataChangingAlert();
          }
        }}
        sortingMode="server"
        sortModel={contentParams.orderBy}
        rows={savingInProgress ? [] : take(tableRows, LIMIT)}
        rowCount={tableContent?.rowsCount ?? 0}
        rowHeight={36}
        rowsPerPageOptions={[LIMIT]}
      />
      {tableContent?.columns &&
        <>
          <DrawerContainer
            open={drawerOpen["right"]}
            anchor={"right"}
            justifyHeaderContent="space-between"
            headerComponents={[
              <Typography key="right_drawer_title">{t("filter")}</Typography>,
              <Box key="right_drawer_buttons">
                {drawerOpen && <IconButton
                  aria-label="restore"
                  onClick={() => setPendingFilter({ ...pendingFilter, ...{ exactFilter: {} } })}
                  key="drawer_component_restore"
                >
                  <Refresh />
                </IconButton>}
                <IconButton
                  aria-label="close"
                  onClick={() => toggleDrawer("right", false)}
                >
                  <Close />
                </IconButton>
              </Box>
            ]}
            onClose={() => toggleDrawer("right", false)}
            width={{
              xs: "100%",
              sm: "55%",
              md: "40%",
              lg: "30%"
            }}
          >
            <FilterForm
              filter={pendingFilter.exactFilter}
              columns={tableContent!.columns}
              hideForm={!drawerOpen["right"]}
              onFormHide={(filter: FilterParams) => setPendingFilter({ ...pendingFilter, ...{ exactFilter: filter } })}
              onSubmit={(filter: FilterParams) => {
                setPendingFilter({ ...pendingFilter, ...{ exactFilter: filter } });
                setContentParams({ ...contentParams, ...{ clauses: filter, rawQuery: undefined } });
                toggleDrawer("right", false);
                setActionResult({ type: "info", message: t("datatable.actions.filtered") });
              }}
            />
          </DrawerContainer>
          <DrawerContainer
            open={drawerOpen["bottom"]}
            anchor={"bottom"}
            justifyHeaderContent="space-between"
            headerComponents={[
              <Typography key="bottom_drawer_title">
                {bottomDrawerContent?.title}
              </Typography>,
              <Box key="bottom_drawer_buttons">
                <IconButton
                  aria-label="close"
                  onClick={closeBottomDrawer}
                >
                  <Close />
                </IconButton>
              </Box>
            ]}
            onClose={closeBottomDrawer}>
            {bottomDrawerContent?.element}
          </DrawerContainer>
        </>}
    </Card>
  );
}

export default DataTable;
