import { AccountCircle } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { MenuIconButton } from "../IconButton";

interface ProfileDropdownProps {
  title: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export const ProfileDropdown = (props: ProfileDropdownProps) => {

  const { title, onClick } = props;

  return (
    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }} onClick={onClick}>
      <Box sx={{ mr: 1, textAlign: "right" }}>
        <Typography >{title}</Typography>
      </Box>
      <MenuIconButton icon={<AccountCircle fontSize="medium" />} />
    </Box>
  );
}