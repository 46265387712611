import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../services/azureAuth";
import { IPublicClientApplication } from "@azure/msal-browser";
import { Button, Container, Typography } from "@mui/material";
import { useThemedStyles } from "../../hooks/themedStyles";
import { getBoxStyle, getContainerStyle } from "./login.styles";
import { Box } from "@mui/system";
import { useTranslation } from 'react-i18next';

async function handleLogin(instance: IPublicClientApplication) {
  try {
    await instance.loginRedirect(loginRequest);
  } catch (error: any) {
    console.log(error)
  }
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
const SignInButton = () => {
  const { instance } = useMsal();

  const containerStyle = useThemedStyles(getContainerStyle)
  const boxStyle = useThemedStyles(getBoxStyle)
  const { t } = useTranslation("translations")

  return (
    <Container sx={containerStyle} fixed>
      <Box sx={boxStyle}>
        <Typography variant="h3">{t("login.welcome")}</Typography>
        <Typography>{t("login.instructions")}</Typography>
        <Button onClick={() => handleLogin(instance)}>
          {t("login.login_btn")}
        </Button>
      </Box>
    </Container>
  );
};

export const SignInView = () => {
  return <SignInButton />;
}
