import { Box, Button, Divider, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import _ from "lodash";
import { reduce } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface FormFilterProps {
  title: string;
  key: string;
  selected: string;
  options: {
    value: string;
    name: string;
  }[];
}

interface FilterFormProps {
  filterContent: FormFilterProps[];
  onChange: (filters: { [key: string]: string }) => void;
  onFilterClick?: () => void;
}

export const FilterForm = (props: FilterFormProps) => {

  const { filterContent, onChange, onFilterClick } = props;

  const { t } = useTranslation("translations");

  const [filter, setFilter] = useState<{ [key: string]: string }>(reduce(filterContent, (result, value) => {
    return { ...result, [value.key]: value.selected }
  }, {}));

  const handleFilterChange = useCallback((key: string, value: string) => {
    setFilter(prev => {
      let newState = { ...prev, [key]: value };

      newState = _(Object.keys(newState)).dropRightWhile(s => s !== key).reduce((result, key) => {
        return { ...result, [key]: newState[key] }
      }, {});

      onChange(newState);
      return newState;
    });
  }, [filterContent]);


  const canSubmit = useMemo(() => !!onFilterClick, [onFilterClick]);


  const handleFilterClick = useCallback(() => {
    if(canSubmit) onFilterClick!();
  }, [canSubmit, onFilterClick]);


  return (
    <Box>
      <Box height={'calc(100vh - 96px)'} overflow="scroll" p={1}>
        {filterContent.map(c => {
          const { title, options, key } = c;
          return (
            <Box key={`select_${c.key}`} mb={2}>
              <InputLabel shrink>{title}</InputLabel>
              <Select
                fullWidth
                variant="standard"
                value={filter[key] ?? ""}
                onChange={(event: SelectChangeEvent<string>) => handleFilterChange(c.key, event.target.value)}
              >
                {options.map((o, index) => {
                  return <MenuItem key={`option_${index}`} value={o.value}>{o.name}</MenuItem>
                })}
              </Select>
            </Box>
          )
        })}
      </Box>
      <Divider />
      <Box p={1}>
        <Button type="submit" variant="contained" color="info" fullWidth disabled={!canSubmit} onClick={handleFilterClick}>
          {t("filter")}
        </Button>
      </Box>
    </Box>
  )
}