import { useCallback, useEffect, useState } from "react";
import { isEmpty, set, some, map, get, find } from "lodash";
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { ConfirmDialogProps } from "../Dialog";
import { FilterForm } from "./FilterForm";
import { CellChange, DateCell, DefaultCellTypes, Id, NumberCell, ReactGrid, TextCell } from "@silevis/reactgrid";
import { ActionResultBoxProps } from "../DataTable/ActionBox";
import "@silevis/reactgrid/styles.css";
import { TableSettings, useTableSettings } from "../../hooks/tableSettingsHook";
import { DataRow, FilterContent as IFilterContent } from "../../hooks/azureTableHooks";
import { postFormContent } from "../../services/requests/azureTable";
import { TFormFilter, useFormContent } from "../../hooks/planningFormHooks";
import { FormToolbar } from "./FormToolbar";
import { FilterDrawer } from "./FormFilterDrawer";


interface DataTableProps {
  tableAccessor?: string;
  setDialogData: (data?: ConfirmDialogProps) => void;
}

function FormTable(props: DataTableProps) {

  const { tableAccessor, setDialogData } = props;

  const location = useLocation();
  const { t } = useTranslation("translations");

  const [changedRows, setChangedRows] = useState<DataRow[]>([])
  const [formFilter, setFormFilter] = useState<TFormFilter>({});
  const [drawerOpen, setFilterFormOpen] = useState(false);
  const [actionResult, setActionResult] = useState<ActionResultBoxProps>();
  const [canSave, setCanSave] = useState(false);

  const { canSubmitForm, filterContent, headerRow, rows, columns, title, queryCompleted, getOriginalRow, getDisplayCell, updateFormContent }
    = useFormContent(tableAccessor, formFilter);
  const { getTableSettings, setColumnWidth } = useTableSettings({ tableAccessor });

  const [tableSettings, setTableSettings] = useState<TableSettings>(getTableSettings());
  const [currentFilterContent, setCurrentFilterContent] = useState<IFilterContent[]>();
  const [formName, setFormName] = useState(title);


  useEffect(() => {
    const rowsChanged = !isEmpty(changedRows);
    setCanSave(rowsChanged);
    window.history.pushState("", "", location.pathname + (rowsChanged ? "/#!" : ""));
  }, [changedRows, location.pathname])


  // Table actions
  const clearTable = useCallback(() => {
    setChangedRows([]);
    updateFormContent();
  }, [updateFormContent])


  useEffect(() => {
    clearTable();
    setFormFilter({});
  }, [tableAccessor, clearTable]);


  useEffect(() => {
    if (some(filterContent)) setCurrentFilterContent(filterContent);
  }, [filterContent]);


  useEffect(() => {
    if (!!title) setFormName(title);
  }, [title]);


  const getFormColumns = useCallback(() => {
    return columns.map(c => {
      return {
        columnId: c.accessor,
        resizable: true,
        width: get(tableSettings.columns, c.accessor)?.width ?? c.width ?? 150
      }
    }) ?? [];
  }, [columns, tableSettings]);


  const getFormRows = useCallback(() => {
    return map(rows, (row, idx) => {
      const changedRow = changedRows.find(c => c.Ky === row.Ky);
      return {
        rowId: row.Ky ?? idx,
        cells: map(columns, col => {
          return getDisplayCell(col, get(changedRow, `${col.accessor}_new`) ?? row[col.accessor]);
        }) ?? [] as DefaultCellTypes[]
      }
    })
  }, [rows, changedRows]);


  const getCellValue = (c: CellChange, prevValue: boolean = false) => {
    const cell = prevValue ? c.previousCell : c.newCell;

    if (c.type === "number") {
      const value = (cell as NumberCell).value;
      return isNaN(value) ? null : value;
    } else if (c.type === "date") {
      return (cell as DateCell).date ?? null;
    } else {
      return (cell as TextCell).text ?? null;
    }
  }


  const handleValueChanges = useCallback((cellChanges: CellChange[]) => {

    const newChangedData: DataRow[] = [];
    cellChanges.forEach(c => {
      const { rowId, columnId } = c;
      const newValue = getCellValue(c);
      const changedRow = find(changedRows, r => r.Ky === rowId);

      if (!!changedRow) {
        if (get(changedRow, `${columnId}`) === newValue) {
          delete changedRow[`${columnId}_new`];
        } else {
          set(changedRow, `${columnId}_new`, newValue)
        }
      } else {
        const origRow = getOriginalRow(rowId);
        if (origRow[columnId] !== newValue) {
          newChangedData.push({ ...origRow, [`${columnId}_new`]: newValue })
        }
      }
    });

    setChangedRows([...changedRows, ...newChangedData].filter(d => {
      return some(Object.keys(d), k => k.endsWith("_new"));
    }));
  }, [setChangedRows, getCellValue, getOriginalRow]);


  const handleColumnResize = useCallback((columnId: Id, width: number) => {
    setTableSettings({ ...set(tableSettings, `columns.${columnId}.width`, width) });
    setColumnWidth(columnId.toString(), width);
  }, [tableSettings]);


  const toggleFilterOpen = useCallback(() => {
    setFilterFormOpen(prev => !prev);
  }, []);


  const handleRestore = useCallback(() => {
    clearTable();
    setActionResult({ type: "info", message: t("planning_form.actions.restored") });
  }, [clearTable]);


  const handleSave = useCallback(async () => {
    const res: { success: boolean } = (await postFormContent(tableAccessor, changedRows));
    if (res.success) {
      await updateFormContent();
      clearTable();
      setActionResult({ type: "success", message: t("planning_form.actions.saved") });
    } else {
      setActionResult({ type: "error", message: t("planning_form.actions.failed") });
    }
  }, [tableAccessor, changedRows]);


  const handleFilter = useCallback(() => {
    if (canSubmitForm) {
      setFormFilter(prev => ({ ...prev, submit_form: 1 }))
      setFilterFormOpen(false);
    }
  }, [canSubmitForm]);


  const Toolbar = useCallback(() => {
    return (
      <FormToolbar
        action={actionResult}
        canSave={canSave}
        formName={formName}
        filters={queryCompleted ? currentFilterContent : undefined}
        onFilterOpen={toggleFilterOpen}
        onRestore={handleRestore}
        onSave={handleSave} />
    )
  }, [actionResult, canSave, formName, currentFilterContent, queryCompleted, toggleFilterOpen, handleRestore, handleSave]);


  const MissingFilterValueMessage = useCallback(() => {
    return <Typography p={2} variant="h5">{t("planning_form.missing_filter_values")}</Typography>;
  }, []);


  const FilterContent = useCallback(() => {
    if (!currentFilterContent) return null;
    return (
      <FilterForm
        filterContent={currentFilterContent} 
        onChange={setFormFilter}
        onFilterClick={canSubmitForm ? handleFilter : undefined} />
    )
  }, [currentFilterContent]);

  return (
    <>
      <Toolbar />
      {isEmpty(rows)
        ? <MissingFilterValueMessage />
        : <ReactGrid
          columns={getFormColumns()}
          rows={[headerRow, ...getFormRows()]}
          enableFillHandle={true}
          stickyTopRows={1}
          stickyLeftColumns={1}
          onCellsChanged={handleValueChanges}
          onColumnResized={handleColumnResize}
        />}
      <FilterDrawer open={drawerOpen} closeDrawer={toggleFilterOpen} >
        <FilterContent />
      </FilterDrawer>
    </>
  );
}

export default FormTable;
