

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useMemo, useReducer } from "react";

const initialState = {
  miniSidenav: true,
  transparentSidenav: false,
  whiteSidenav: false,
  sidenavColor: "info",
  transparentNavbar: true,
  fixedNavbar: true,
  openConfigurator: false,
  direction: "ltr",
  layout: "dashboard",
  darkMode: false,
};

// The Material Dashboard 2 PRO React main context
const MaterialUI = createContext({
  controller: initialState,
  dispatch: ((action: Action) => {}) as React.Dispatch<Action>,
});

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

interface Action {
  type: "MINI_SIDENAV"
  | "TRANSPARENT_SIDENAV"
  | "WHITE_SIDENAV"
  | "SIDENAV_COLOR"
  | "TRANSPARENT_NAVBAR"
  | "FIXED_NAVBAR"
  | "OPEN_CONFIGURATOR"
  | "DIRECTION"
  | "LAYOUT"
  | "DARKMODE";
  value: any
};

type Dispatch = React.Dispatch<Action>;

// Material Dashboard 2 PRO React reducer
function reducer(state: typeof initialState, action: Action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 PRO React context provider
function MaterialUIControllerProvider({
  children,
}: {
  children?: React.ReactChild | React.ReactChild[];
}) {

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => { return { controller, dispatch };},[controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 PRO React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Context module functions
const setMiniSidenav = (dispatch: Dispatch, value:any) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch: Dispatch, value:any) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch: Dispatch, value:any) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch: Dispatch, value:any) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch: Dispatch, value:any) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch: Dispatch, value:any) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch: Dispatch, value:any) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch: Dispatch, value:any) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch: Dispatch, value:any) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch: Dispatch, value:any) => dispatch({ type: "DARKMODE", value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
};
