import { Button, Dialog as DialogMui, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useTranslation } from 'react-i18next';

export interface ConfirmDialogProps {
  dialogContentText?: string;
  children?: JSX.Element | JSX.Element[];
  handleClose?: () => void;
  handleConfirm?: () => void;
  open?: boolean;
  title?: string;
  type?: "alert" | "confirm";
}

export default function Dialog(props: ConfirmDialogProps) {

  const { children, dialogContentText, open, title, type, handleClose, handleConfirm } = props;
  const { t } = useTranslation("translations");

  if (!dialogContentText && !children) return null;

  return (
    <DialogMui
      open={open ?? true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogContentText}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        {type === "alert"
          ? (
            <Button onClick={handleClose}>
              {t("dialog.ok")}
            </Button>
          )
          : (
            <>
              <Button onClick={handleClose}>
                {t("dialog.cancel")}
              </Button>
              <Button onClick={handleConfirm} autoFocus>
                {t("dialog.confirm")}
              </Button>
            </>
          )
        }
      </DialogActions>
    </DialogMui>
  )
}
