import { ContentColumn } from "../../hooks/azureTableHooks";
import { Box, Button, TextField, Grid } from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import _, { isEmpty } from "lodash"
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import textAreaStyle from "./Styles.ts/monospaceTextArea";

interface RawFilterFormProps {
  tableName?: string;
  filter: string;
  columns: ContentColumn[];
  hideForm: boolean;
  error?: string;
  rawQueryBaseScript: string;
  onFormHide: (filter: string) => void;
  onSubmit: (raw: string) => void;
}

type Inputs = {
  [accessor: string]: string
};

export function RawFilterForm(props: RawFilterFormProps) {

  const { columns, onFormHide, onSubmit, filter, hideForm, tableName, error, rawQueryBaseScript } = props;
  const { register, handleSubmit, getValues, reset } = useForm<Inputs>();
  const { t } = useTranslation("translations");

  const submit: SubmitHandler<Inputs> = () => {
    onSubmit(getValues().rawQuery);
  }

  useEffect(() => {
    if (hideForm) onFormHide(getValues().rawQuery);
  }, [hideForm])

  useEffect(() => {
    if (isEmpty(filter)) reset();
  }, [filter])

  return (
    <Box p={1} fontFamily="monospace">
      <form onSubmit={handleSubmit(submit)}>
        <Grid container>
          <Grid item>
            {`${rawQueryBaseScript} ...`}
          </Grid>
          <Grid container item direction="column" spacing={1}>
            <Grid item>
              <TextField
                {...register("rawQuery")}
                id="raw-filter-input"
                multiline
                rows={7}
                fullWidth
                defaultValue={filter}
                InputProps={textAreaStyle()}
                sx={{ whiteSpace: "nowrap" }}
              />
            </Grid>
            {error && <Grid item>
              <TextField
                label={t("filter_error_label")}
                value={error}
                multiline
                fullWidth
                InputProps={textAreaStyle()}
              />
            </Grid>}
            <Grid container item justifyContent="flex-end">
              <Button type="submit" variant="contained" color="info" size="small">
                {t("filter")}
              </Button>
            </Grid>
            <Grid item sx={{ fontSize: 12 }}>
              <ul style={{ paddingLeft: 12 }}>
                {columns.map(c => <li key={"li_raw_field_" + c.accessor}>{`${c.accessor}: ${c.description}`}</li>)}
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}