export const customComponents = {
  MuiToolbar: {
    styleOverrides: {
      root: {
        '@media (min-width: 0px)': {
          minHeight: "42px"
        },
        '@media (min-width: 600px)': {
          paddingTop: 2,
          paddingBottom: 2,
          paddingLeft: 8,
          paddingRight: 8
        },
      }
    }
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        padding: "12px 12px 8px"
      }
    }
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: "8px 12px 24px"
      }
    }
  }
}